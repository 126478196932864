@use "./config/" as *;
@forward "./main-scss/";
@forward "./listing/listing-common";
@forward "news-listing-page";
@forward "team-listing-page";
@forward "./partials/pagination";
.waf-search {
    & > .layout-wrapper > .waf-head {
        .head-wrap {
            margin-bottom: 0;
            padding-block: 0;
        }
    }
    .site-search-page {
        &-section {
            width: auto;
            top: unset;
            right: unset;
            @extend %relative;
            &::after {
                content: '';
            }
            @extend %secondary-1000-bg;
            .site-search-page-input {
                padding-inline: var(--space-10) 11rem;
                min-height: 4.9rem;
                border: 0;
                padding-right: 12rem;
                @extend %neutral-600;
                @extend %font-12-pm;
                @extend %w-100;
                @extend %p-4;
                @extend %half-radius;
                @extend %pure-white-900-bg;
            }
            .btn-search {
                @extend %uppercase;
                @include position(var(--space-11), var(--space-12), null, null);
                @include btn-animation;
                &::after {
                    width: 0;
                    font-size: 0;
                }
                &:hover {
                    &::after {
                        width: 1.4rem;
                        font-size: 1.4rem;
                    }
                }
            }
        }
        &-form {
            padding-block: var(--space-8) var(--space-5);
            @extend %px-8;
            &::before {
                @include icon(search, 16);
                @include position(5rem, null, null, var(--space-12));
            }
            .btn-text {
                @extend %uppercase;
            }
        }
    }
    .recommended {
        &-text {
            @extend %font-14-pm;
            @extend %pure-white-900;
        }
        &-search {
            @extend %pb-5;
            @extend %px-8;
        }
    }
    .tab {
        &-container {
            @extend %mb-6;
        }
        &-list {
            border-top: .1rem solid clr(neutral-100, 2);
            @extend %flex-v-center;
            @extend %w-100;
            @extend %secondary-1000-bg;
        }
        &-item {
            flex-grow: 1;
            text-align: center;
            border-bottom: solid transparent;
            @extend %p-2;
            .text {
                opacity: .6;
                @extend %font-12-pm;
                @extend %pure-white-900;
            }
        }
        &-item.active {
            border-bottom: .3rem solid clr(primary-300);
            .text {
                opacity: unset;
                @extend %primary-300;
            }
        }
        &-name {
            background-color: unset;
            height: unset;
        }
        &-result-item {
            @extend %pure-white-900-bg;
            @extend %px-4;
            @extend %mb-6;
        }
    }
    .name {
        @include truncate-text(2);
        @extend %font-14-pb;
        @extend %neutral-900;
        @extend %pr-1;
    }
    .year {
        @extend %font-12-pm;
        @extend %neutral-900;
    }
    .team {
        @extend %font-12-pm;
        @extend %neutral-900;
    }
    .btn-more {
        --_border-color: var(--secondary-800);
        --_more-color: var(--secondary-800);
        padding-block: 0;
        @include btn-animation;
        &:active {
            @extend %secondary-800;
        }
    }
    .squad {
        &-item {
            @extend %w-100;
            @extend %relative;
            @extend %flex;
            @extend %py-5;
        }
        &-content {
            @extend %pl-3;
            @extend %flex-wrap;
            @extend %flex-v-center;
        }
        &-thumbnail {
            width: 3.6rem;
            height: 3.6rem;
            flex-shrink: 0;
            border: .1rem solid clr(primary-300);
            overflow: hidden;
            @extend %secondary-50-bg;
            @extend %circle-radius;
        }
        &-action {
            @extend %mt-2;
        }
        &-team {
            @extend %w-100;
        }
        &-listing {
            @extend %flex;
            @extend %flex-wrap;
            @extend %pb-4;
        }
        .btn-more {
            opacity: 0;
            inset: 0;
            @extend %w-100;
            @extend %h-100;
            @extend %absolute;
            @extend %d-block;
        }
        .head-wrap {
            margin-bottom: 0;
        }
    }
    .waf {
        &-head {
            .head-wrap {
                border-bottom: .1rem solid clr(neutral-50);
                @extend %mb-5;
                @extend %py-4;
                @extend %flex-nowrap;
                .title {
                    width: unset;
                    @extend %d-none;
                    @extend %uppercase;
                }
            }
            @include drop-arrow();
            --_border-color: var(--secondary-800);
            --_more-color: var(--secondary-800);
        }
        &-listing {
            padding: unset;
            @extend %mb-6;
            .waf {
                &-head {
                    padding-bottom: unset;
                    .sub-title {
                        font-size: 1.6rem;
                        @extend %uppercase;
                    }
                    .head-tab {
                        @extend %d-block;
                        a {
                            @extend %uppercase;
                        }
                    }
                }
                &-body {
                    padding: unset;
                }
            }
            .article {
                &-title {
                    font-size: 1.4rem;
                    @extend %neutral-800;
                    @extend %mb-3;
                }
                &-meta {
                    align-items: center;
                    .meta {
                        @extend %secondary-800;
                        &-date {
                            @extend %neutral-500;
                        }
                        &-author {
                            @extend %d-none;
                        }
                    }
                }
            }
            .second-list {
                .article {
                    &-item {
                        border-bottom: .1rem solid clr(neutral-50);
                        @extend %pb-3;
                    }
                    &-content {
                        padding-left: var(--space-4);
                    }
                    &-thumbnail {
                        width: 12rem;
                    }
                }
            }
        }
        &-series {
            @extend %pure-white-900-bg;
            @extend %half-radius;
            @extend %p-4;
            .head-wrap {
                @include border(1, neutral-400, 1, bottom);
                @extend %pb-4;
            }
            .event {
                &-listing {
                    padding-bottom: var(--space-4);
                    @include listing-card(horizontal);
                    @include card-count(1, var(--space-4), true);
                    @extend %mt-4;
                }
                &-meta {
                    margin-top: .6rem;
                    .meta {
                        @extend %neutral-600;
                        &-author,
                        &-category {
                            @extend %d-none;
                        }
                        &-date,
                        &-year {
                            @extend %font-10-pm;
                        }
                    }
                }
                &-wrap {
                    gap: var(--space-2);
                    @extend %flex-v-center;
                }
                &-thumbnail {
                    width: 9rem;
                    .image {
                        aspect-ratio: 4 / 3;
                        object-fit: cover;
                        @extend %half-radius;
                    }
                }
                &-content {
                    width: calc(65% - var(--space-2));
                }
                &-title {
                    @extend %pb-1;
                    .title {
                        line-height: 2rem;
                        @extend %secondary-800;
                        @extend %font-14-pb;
                    }
                }
            }
        }
        &-news {
            .img-box {
                aspect-ratio: 4 / 3;
            }
        }
        &-video {
            .article {
                &-content {
                    border-radius: 0 var(--half-radius) var(--half-radius) 0;
                    margin-top: -2.4rem;
                    width: 95%;
                    @extend %neutral-50-bg;
                    @extend %p-4;
                }
                &-meta {
                    .meta-category {
                        left: unset;
                    }
                }
            }
        }
        &-video,
        &-photos {
            .article {
                &-list {
                    overflow-x: auto;
                    gap: var(--space-4);
                    @extend %pb-5;
                    @extend %flex;
                }
                &-item {
                    flex-shrink: 0;
                    @extend %w-90;
                }
                &-thumbnail {
                    img {
                        object-fit: contain;
                    }
                }
            }
        }
    }
    .photos {
        .waf-listing {
            .article {
                &-content {
                    @extend %neutral-100-bg-2;
                    @extend %p-4;
                }
                &-meta {
                    .meta-category {
                        left: unset;
                    }
                }
            }
        }
        .img-box {
            border-radius: 0 var(--half-radius) 0 0;
        }
    }
    .team {
        &-action {
            @extend %flex;
            .btn-text {
                font-size: 0;
            }
            .btn-more {
                border: 0;
                @include position-combo(tl);
                @extend %h-100;
                @extend %w-100;
                &::before,
                &::after {
                    content: unset;
                }
                &:hover {
                    &::after {
                        content: unset;
                    }
                }
            }
        }
        &-thumbnail {
            height: 3.5rem;
            width: var(--_team-logo);
        }
        &-wrap {
            gap: 1.4rem;
            border: .1rem solid clr(neutral-1000);
            @extend %flex-v-center;
            @extend %mb-4;
            @extend %p-4;
        }
        &-image {
            @extend %w-100;
            @extend %h-100;
        }
        &-name {
            @extend %neutral-800;
            @extend %font-14-pb;
        }
        &-item {
            @extend %relative;
        }
    }
    .search-no-result {
        margin-bottom: 3rem;
        @extend %font-16-pb;
        @extend %neutral-900;
        @extend %pure-white-900-bg;
        @extend %capitalize;
        @extend %text-center;
        @extend %p-4;
        &::after,
        &::before {
            content: "---";
            @extend %mx-3;
        }
    }
    .pagination-result {
        @extend %pb-4;
    }
    .team-listing {
        @extend %pb-4;
    }
}
@include mq(col-md) {
    .waf-search {
        & > .layout-wrapper > .waf-head {
            .head-wrap {
                margin-bottom: var(--space-5);
                padding-block: var(--space-3);
            }
        }
        .site-search-page-section {
            border-radius: var(--half-radius) var(--half-radius) 0 0;
            .site-search-page-input {
                min-height: 5.2rem;
            }
        }
        .waf {
            &-video,
            &-photos {
                .article-item {
                    width: calc((100% - var(--space-8)) / 3);
                }
            }
            &-listing {
                .second-list {
                    .article {
                        &-content {
                            padding-left: 0;
                        }
                        &-meta {
                            .meta-category {
                                top: var(--space-2);
                            }
                        }
                        &-thumbnail {
                            width: 28rem;
                        }
                        &-title {
                            margin-top: var(--space-3);
                        }
                    }
                }
                .article-meta {
                    .meta {
                        font-size: 1.0rem;
                    }
                }
            }
            &-series {
                .event-wrap {
                    justify-content: unset;
                }
            }
            &-news {
                .article {
                    &-list {
                        display: flex;
                        flex-direction: column;
                    }
                    &-wrap {
                        flex-direction: row;
                        gap: 1.6rem;
                    }
                }
                .waf-listing {
                    .second-list {
                        .article-content {
                            padding-block: var(--space-2);
                        }
                    }
                }
                .img-box {
                    aspect-ratio: 16 / 9;
                }
            }
            &-series {
                .event {
                    &-item {
                        width: calc((100% - var(--space-8)) / 3);
                    }
                    &-title {
                        .title {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
        .team {
            &-listing {
                display: flex;
                flex-wrap: wrap;
                gap: 1.6rem;
            }
            &-item {
                width: calc((100% - var(--space-8)) / 2);
            }
            &-wrap {
                margin-bottom: 0;
            }
        }
        .tab {
            &-item {
                padding-block: var(--space-4);
            }
            &-list {
                border-radius: 0 0 var(--half-radius) var(--half-radius);
                padding-inline: var(--space-4);
                justify-content: space-between;
            }
        }
        .squad-item {
            width: calc((100% - var(--space-8)) / 3);
        }
        .search-no-result {
            font-size: 1.8rem;
        }
    }
}