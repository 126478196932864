@use "../config/" as *;
.waf-teamlisting {
    @extend %p-4;
    @extend %pure-white-900-bg;
    @extend %my-6;
    .head-tab {
        @extend %d-none;
    }
    .head-wrap {
        flex-direction: column;
        align-items: flex-start;
    }
    .title,
    .sub-title {
        @extend %font-16-pb;
        @extend %secondary-800;
        @extend %uppercase;
        @extend %mb-5;
    }
    .team {
        &-badge {
            @extend %mb-8;
            @extend %relative;
            .img-box {
                height: 3rem;
                width: 5rem;
            }
            &::after {
                content: "";
                height: .1rem;
                @include position(null, null, var(--space-4-neg), null, absolute);
                @extend %neutral-100-bg-2;
                @extend %w-100;
            }
        }
        &-title {
            height: 3rem;
            @include position(1.6rem, null, null, 8rem, absolute);
            @extend %flex-n-c;
            @extend %font-12-pb;
            @extend %neutral-800;
            @extend %uppercase;
        }
        &-item {
            border: .1rem solid clr(neutral-1000);
            @extend %p-4;
        }
        &-listing {
            @extend %flex-column;
            @extend %gap-4;
        }
        &-item {
            @extend %relative;
        }
        &-action {
            @extend %flex;
            @extend %gap-2;
            .btn {
                flex: 1;
                @include btn-animation();
            }
            .btn-more {
                @extend %neutral-100-bg-3;
            }
        }
    }
}
@include mq(col-md) {
    .waf-teamlisting {
        .team {
            &-title {
                font-size: 1.4rem;
            }
            &-listing {
                flex-direction: row;
                flex-wrap: wrap;
                gap: 0;
                border: .1rem solid var(--neutral-1000);
            }
            &-item {
                width: 50%;
                border: 0;
                border-bottom: .1rem solid var(--neutral-1000);
                margin-bottom: -.1rem;
                &:not(:nth-child(even)) {
                    border-right: .1rem solid var(--neutral-1000);
                }
            }
        }
    }
}